//tailwindcss
@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../node_modules/@eset/eset-shared/styles/styles.scss';

//TYPOGRAPHY
//Font CDN Import
@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Light-Italic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Book-Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Demi-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Medium-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.woff2') format('woff2'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Alt-Bold-Italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Fedra';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Fedra Display';
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.eot');
  src: url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.eot?#iefix') format('embedded-opentype'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.woff') format('woff'),
    url('https://static.eset.co.za/fonts/web/Fedra-Sans-Display-Heavy.ttf') format('truetype');
  font-style: normal;
}
:root {
  --ion-color-primary: #0096a1;
  --ion-color-primary-rgb: 0, 150, 161;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #00848e;
  --ion-color-primary-tint: #1aa1aa;
  --ion-font-family: 'Fedra', sans-serif;
  --highlight-color: blue !important;
}

body {
  font-family: 'Fedra', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  height: 100%;
}

iframe {
  min-height: 21px;
}

.default-tooltip {
  font-size: 0.75rem;
  background-color: theme('colors.grey.blue') !important;
}

.default-tooltip::after {
  display: none !important;
  border-color: theme('colors.grey.blue') transparent transparent !important;
}

ion-icon:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}

@keyframes error {
  0%,
  100% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  50% {
    transform: translateY(-20%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
}
.animate-error {
  animation: error 0.4s 2 !important;
}

@layer base {
  a {
    @apply text-primary cursor-pointer hover:text-turquoise-bright;
  }

  input {
    @apply p-2 w-full;
  }

  input::placeholder {
    @apply text-grey-mid/50 font-light text-sm;
  }

  textarea {
    @apply border shadow border-solid border-grey-concrete rounded p-2 w-full focus:outline-none focus:border-turquoise-bright font-normal;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  select {
    @apply text-grey-blue border shadow border-solid border-grey-concrete rounded p-2 w-full focus:outline-none font-normal ring-2 ring-white ring-opacity-0 focus:ring-1 focus:ring-primary h-10 transition-all;
  }

  select.ng-invalid {
    @apply text-grey-mid;
  }

  button,
  .btn-link {
    @apply text-white bg-gradient-to-r from-turquoise-dark to-turquoise-bright hover:bg-gradient-to-br focus:ring-2 focus:outline-none active:ring-0 font-medium rounded-full text-lg px-5 py-2 text-center my-4 disabled:bg-none disabled:bg-grey-concrete whitespace-nowrap shadow;
  }

  label {
    @apply block pb-1 text-grey-blue text-xs font-light;
  }

  table {
    @apply border-b-2 border-solid border-grey-concrete;
  }

  thead {
    @apply text-xs uppercase text-primary border-b-2 border-solid border-grey-concrete;
  }
}

@layer components {
  .header {
    @apply text-black;
  }

  .empty-state {
    @apply block bg-white shadow -mt-0 text-center py-[20vh] font-medium text-3xl  text-grey-mid md:rounded-lg md:m-3;
  }

  .main-header {
    @apply bg-white block shadow sticky top-0 z-20 mb-3 md:rounded md:max-lg:m-3 lg:m-0 lg:relative lg:bg-grey-light-concrete lg:shadow-none;
  }

  .section-header {
    @apply bg-white block shadow p-4 my-3 md:m-3 md:rounded;
  }

  .subsection-header {
    @apply lg:flex lg:items-center;

    .main-header {
      @apply absolute top-0 left-0 z-20 w-full md:w-[calc(100%-1.5rem)] lg:w-fit lg:relative;
    }

    app-nav-tab-header > ul,
    div > ul {
      @apply mt-20 md:mt-24 mb-2 md:mb-1 mx-3 lg:my-0 lg:pt-1;
    }
  }
  .header-content {
    @apply min-h-10;
  }

  .btn-default {
    @apply text-grey-blue h-fit text-xs bg-grey-light-concrete border border-solid border-grey-concrete px-3 py-2 enabled:hover:bg-grey-concrete enabled:hover:border-opacity-75 my-2 disabled:opacity-20 shadow-sm active:shadow-inner focus:ring-opacity-0 focus:ring-0 focus:outline-none transition-all;
  }

  .btn-primary {
    @apply btn-default text-white border-primary bg-primary enabled:hover:bg-turquoise-bright enabled:hover:border-turquoise-bright disabled:bg-primary disabled:border-primary;
  }

  .btn-secondary {
    @apply btn-default text-primary border-primary bg-white enabled:hover:bg-turquoise-bright enabled:hover:border-turquoise-bright enabled:hover:text-white disabled:bg-primary disabled:border-primary;
  }

  .btn-danger {
    @apply btn-default text-white enabled:bg-bright-crimson enabled:border-bright-crimson  enabled:hover:text-white enabled:hover:bg-bright-crimson/60;
  }

  .btn-danger-secondary {
    @apply btn-default text-bright-crimson border-bright-crimson bg-white enabled:hover:bg-bright-crimson enabled:hover:border-bright-crimson enabled:hover:text-white disabled:bg-bright-crimson disabled:border-bright-crimson;
  }

  .btn-warning {
    @apply btn-default text-white enabled:bg-bright-orange enabled:border-bright-orange  enabled:hover:text-white enabled:hover:bg-bright-orange/60;
  }

  .btn-warning-secondary {
    @apply btn-default text-bright-orange border-bright-orange bg-white enabled:hover:bg-bright-orange enabled:hover:border-bright-orange enabled:hover:text-white disabled:bg-bright-orange disabled:border-bright-orange;
  }

  .btn-note {
    @apply btn-default text-white enabled:bg-bright-yellow enabled:border-bright-yellow  enabled:hover:text-white;
  }

  .btn-icon {
    @apply btn-default h-8 w-8 flex items-center justify-center p-0 border border-grey-concrete bg-grey-light-concrete lg:bg-opacity-0 shadow;
  }

  .btn-icon > ion-icon {
    @apply text-lg;
  }

  .btn-xs {
    @apply text-xs px-2 py-1 my-0;
  }

  .btn-lg {
    @apply text-sm px-4 py-2 my-0;
  }

  .default-grid {
    @apply relative overflow-visible text-xs font-medium bg-white shadow md:py-1 px-0 md:my-3 md:rounded md:m-3;
  }

  .default-grid label,
  .grid-row label {
    @apply text-xs;
  }

  .grid-header {
    @apply hidden gap-x-3 gap-y-0 px-2 py-2 uppercase text-primary border-b border-solid border-grey-concrete sticky top-0 bg-white z-10 items-end backdrop-blur-xs bg-opacity-80 lg:grid;
  }

  .grid-body > .grid-row:first-child {
    @apply border-t-0;
  }

  .grid-header > div:first-child,
  .grid-row > div:first-child {
    @apply lg:pl-2;
  }

  .grid-header > div:last-child,
  .grid-row > div:last-child {
    @apply lg:pr-2;
  }

  .grid-header-item,
  .grid-header-item a {
    @apply flex items-center justify-start cursor-pointer font-normal;
  }
  .grid-header-item ion-icon {
    @apply text-lg bg-primary/10 rounded-md p-0.5 mx-1 -mt-0.5;
  }
  .grid-header-item:has(ion-icon),
  .grid-header-item a:has(ion-icon) {
    @apply font-semibold;
  }

  .grid-footer {
    @apply border-t-2 border-solid border-grey-blue bottom-0 bg-white border-opacity-10 backdrop-blur-xs bg-opacity-80;
  }

  .grid-row {
    @apply grid gap-3 font-medium items-start border-t border-t-grey-light-concrete tabular-nums relative hover:bg-grey-light-concrete transition-all p-2 lg:items-center;
  }

  .grid-row-expandable {
    @apply transition-all border-grey-concrete;
  }

  .grid-row-expandable.expanded {
    @apply my-4 shadow-xl overflow-hidden border-y relative z-10 md:border md:shadow-lg md:rounded-lg md:-mx-2;
  }

  .form-group {
    @apply bg-white block shadow border-0 h-fit overflow-hidden rounded-none md:rounded 2xl:flex;
  }

  .form-header {
    @apply text-grey-blue font-medium p-4 2xl:w-1/3 border-b bg-white border-grey-concrete 2xl:border-r 2xl:border-b-0 border-dotted;
  }

  .form-header h4 {
    @apply font-medium;
  }

  .form-header p {
    @apply font-normal text-xs text-grey-mid;
  }

  .form-body {
    @apply p-4 pt-3 2xl:pt-4 2xl:w-2/3 text-grey-blue;
  }

  .form-input {
    @apply relative pt-1 pb-3;
  }

  .form-input input {
    @apply border shadow border-solid border-grey-concrete rounded p-2 w-full h-10 font-normal ring-2 ring-white ring-opacity-0 focus:border-white focus:ring-primary focus:ring-opacity-100 transition-all;
  }
  .form-input label {
    @apply transition-all;
  }

  .form-prefix {
    @apply min-w-fit inline-flex items-center rounded-l-md border shadow border-r-0 border-grey-concrete bg-grey-light-concrete px-2 text-grey-mid text-xs;
  }

  .form-select-card {
    @apply inline-block;
  }

  .form-select-card label {
    @apply px-3 py-2 bg-white text-sm rounded flex items-center border shadow hover:cursor-pointer hover:bg-primary/10 transition-all font-normal;
  }

  .has-error label {
    @apply text-bright-crimson flex space-x-1 items-center animate-error;
  }

  .has-error input,
  .has-error select {
    @apply border-bright-crimson text-bright-crimson focus:border-bright-crimson focus:ring-1 focus:ring-bright-crimson;
  }

  .form-warn {
    @apply text-bright-yellow py-2 text-xs absolute right-0 -mt-2;
  }

  .text-empty-field {
    @apply uppercase tracking-wide text-grey-mid/50 font-mono italic cursor-default;
  }

  .status-badge span {
    @apply bg-grey-mid bg-opacity-20 rounded-full inline-block font-medium leading-6 px-2 lowercase whitespace-nowrap;
  }

  .chip span {
    @apply bg-grey-mid bg-opacity-20 rounded-full inline-block font-medium leading-6 px-2 whitespace-nowrap;
  }

  .form-footer {
    @apply border-t-2 border-solid border-grey-concrete mx-4 py-2 text-right;
  }

  .status-dot {
    @apply relative inline-flex rounded-full h-3 w-3;
  }
}

ion-toast {
  --border-radius: 25px;
  --white-space: normal;
  width: 100%;
  @media screen and (max-width: 767px) {
    --height: auto;
  }
}

ion-toast::part(container) {
  text-align: center;
  font-family: 'Fedra', sans-serif;
  font-weight: 500;
}

ion-toast::part(message) {
  max-width: 500px;
}

ion-toast::part(button) {
  padding: 0px;
  padding-inline-end: 13px;
  height: 40px;
  color: theme('colors.white');
}

ion-nav {
  contain: unset !important;
}
.ion-page {
  position: relative !important;
  contain: unset !important;
}

ion-toast.info-toast {
  --background: theme('colors.blue.electric');
  --color: theme('colors.white');
}

ion-toast.error-toast {
  --background: theme('colors.bright.crimson');
  --color: theme('colors.white');
}

ion-toast.warning-toast {
  --background: theme('colors.bright.orange');
  --color: theme('colors.white');
  font-weight: bold;
}

ion-toast.success-toast {
  --background: theme('colors.bright.green');
  --color: theme('colors.white');
}

ion-toast.auto-height {
  --height: auto;
}

ion-toggle {
  height: 1.5em;
  width: 3em;
  --track-background-checked: theme('colors.primary');
}

ion-checkbox {
  --size: 20px;
  --checkbox-background-checked: theme('colors.primary');
  margin: 0px !important;

  &::part(label) {
    margin-inline-start: 8px;
    margin-top: 6px;
    margin-bottom: 6px;
    font-weight: 400;
  }
  &::part(container) {
    border: 2px solid theme('colors.primary');
    border-radius: 50%;
    padding: 2px;
  }
}
.form-input {
  ion-select {
    --highlight-color-focused: theme('colors.primary');
    --border-color: transparent;
    --padding-start: 8px;
    --padding-end: 0px;
    --padding-top: 0;
    --padding-bottom: 0;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    border: 1px solid theme('colors.grey.concrete');
    border-radius: 8px;
    min-height: 40px;
    font-weight: 400;
  }
  ion-select::part(icon) {
    color: theme('colors.grey.blue');
  }
}

ion-datetime::part(calendar-day active) {
  background: theme('colors.primary');
  border: theme('colors.primary');
  outline: theme('colors.primary');
  color: white;
}

ion-modal {
  --height: auto;
  --border-radius: 1em;
}

ion-modal.sm {
  --width: 400px;
}

ion-modal.xs {
  --width: 200px;
}

ion-modal.drawer-style-sm::part(content) {
  border-radius: 1em;
  position: absolute;
  top: 1em;
  right: 1em;
  display: block;
  width: 350px;
  height: calc(100% - 2em);
  transform: translate3d(200px, 200px, 0);

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    border-radius: 0 0 0 0;
    top: 0;
    right: 0;
  }
}
ion-modal.drawer-style-md::part(content) {
  border-radius: 1em;
  position: absolute;
  top: 1em;
  right: 1em;
  display: block;
  width: 550px;
  height: calc(100% - 2em);
  transform: translate3d(200px, 200px, 0);

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    border-radius: 0 0 0 0;
    top: 0;
    right: 0;
  }
}
ion-modal.drawer-style-lg::part(content) {
  border-radius: 1em;
  position: absolute;
  top: 1em;
  right: 1em;
  display: block;
  width: calc(100vw - 300px);
  height: calc(100% - 2em);
  transform: translate3d(200px, 200px, 0);
}
ion-modal.dialog-style-sm::part(content) {
  border-radius: 1em;
  position: absolute;
  display: block;
  width: 350px;
  min-height: 200px;
  transform: translate3d(200px, 200px, 0);
  @media screen and (max-width: 767px) {
    width: calc(100% - 1em);
    margin: 0.5em;
    bottom: 0;
    right: 0;
  }
}
ion-modal.dialog-style-md::part(content) {
  border-radius: 1em;
  position: absolute;
  display: block;
  width: 550px;
  min-height: 350px;
  transform: translate3d(200px, 200px, 0);

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 1em;
    border-radius: 0 0 0 0;
    top: 0;
    right: 0;
  }
}
ion-icon.ion-accordion-toggle-icon {
  display: none !important;
}
ion-popover::part(content) {
  border-radius: 15px;
}
ion-popover {
  @apply text-xs;
}
ion-popover#other-cart-options {
  @apply text-base;
}
.ng2-tag-input,
.ng2-tag-input__text-input {
  border: none !important;
  box-shadow: none !important;
  font-family: 'Fedra', sans-serif !important;

  tag:not(.readonly):not(.tag--editing):active,
  tag:not(.readonly):not(.tag--editing):focus {
    background: theme('colors.primary') !important;
    color: theme('colors.white') !important;
  }
  tag {
    font-family: 'Fedra', sans-serif !important;
    letter-spacing: normal !important;
    font-size: small !important;
    line-height: 32px !important;
  }
  delete-icon svg {
    height: 30px !important;
  }
  .tag__text {
    padding: 0 0.5em;
  }
  &:focus {
    outline: none !important;
  }
}
.alert-wrapper {
  border-radius: 1rem !important;
  padding: 1rem;
  color: theme('colors.black');
  --max-width: 350px;
  animation: modal-ripple 0.8s linear infinite;
}

.alert-warning {
  .alert-head:before {
    background-color: theme('colors.bright.orange');
  }
  .alert-wrapper {
    animation: modal-ripple-warning 0.8s linear infinite;
  }
  .alert-title {
    color: theme('colors.bright.orange') !important;
  }
  .alert-sub-title {
    font-weight: 500 !important;
    line-height: 1.3em !important;
    font-size: 1.1em !important;
  }
  .alert-message {
    color: theme('colors.grey.blue') !important;
    line-height: 1.25em !important;
    font-size: 0.95em !important;
    font-weight: 300 !important;
  }
}

@-webkit-keyframes modal-ripple {
  0% {
    box-shadow: 0 2px 10px rgba(255, 196, 37, 0.5), 0 0 0 0 rgba(255, 196, 37, 0.5), 0 0 0 5px rgba(255, 196, 37, 0.5),
      0 0 0 10px rgba(255, 196, 37, 0.5);
  }

  100% {
    box-shadow: 0 2px 10px rgba(255, 196, 37, 0.5), 0 0 0 5px rgba(255, 196, 37, 0.5),
      0 0 0 10px rgba(255, 196, 37, 0.5), 0 0 0 20px rgba(255, 196, 37, 0);
  }
}

@keyframes modal-ripple {
  0% {
    box-shadow: 0 2px 10px rgba(255, 196, 37, 0.5), 0 0 0 0 rgba(255, 196, 37, 0.5), 0 0 0 5px rgba(255, 196, 37, 0.5),
      0 0 0 10px rgba(255, 196, 37, 0.5);
  }

  100% {
    box-shadow: 0 2px 10px rgba(255, 196, 37, 0.5), 0 0 0 5px rgba(255, 196, 37, 0.5),
      0 0 0 10px rgba(255, 196, 37, 0.5), 0 0 0 20px rgba(255, 196, 37, 0);
  }
}

@keyframes modal-ripple-warning {
  0% {
    box-shadow: 0 2px 10px rgba(255, 79, 33, 0.5), 0 0 0 0 rgba(255, 79, 33, 0.5), 0 0 0 5px rgba(255, 79, 33, 0.5),
      0 0 0 10px rgba(255, 79, 33, 0.5);
  }

  100% {
    box-shadow: 0 2px 10px rgba(255, 79, 33, 0.5), 0 0 0 5px rgba(255, 79, 33, 0.5), 0 0 0 10px rgba(255, 79, 33, 0.5),
      0 0 0 20px rgba(255, 79, 33, 0);
  }
}

.alert-title,
.alert-sub-title {
  text-align: center;
  color: theme('colors.black') !important;
}
.alert-head {
  padding: 10px !important;
}

.alert-title {
  font-size: 1.5em !important;
  line-height: 1.25em !important;
  font-weight: 600 !important;
  margin-bottom: 0.5em !important;
}
.alert-head::before {
  content: '';
  display: flex;
  margin: auto;
  background-color: theme('colors.bright.yellow');
  mask-image: url('/assets/warning.svg');
  height: 4em;
  width: 4em;
  margin-bottom: 0.5em;
  margin-top: -0.5em;
}

.alert-sub-title {
  font-size: 1.25rem !important;
  line-height: 1rem !important;
  margin: 1.25rem 0 0.5em !important;
  color: theme('colors.black') !important;
}
.alert-message {
  font-size: 0.9rem !important;
  line-height: 1.15rem !important;
  font-weight: 400 !important;
  text-align: center;
  padding: 0 10px 15px !important;
  color: theme('colors.black') !important;
}

.alert-button-group {
  display: flex;
  justify-content: space-between !important;
}

.alert-button-role-confirm {
  margin: 0px 0.5rem !important;
  border-radius: 25px !important;
  padding: 0.25rem 1.25rem !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: theme('colors.white') !important;
  background-color: theme('colors.primary') !important;

  span {
    text-transform: none;
  }
}

.alert-button-role-cancel {
  margin: 0px 0.5rem !important;
  border: 1px solid theme('colors.grey.concrete') !important;
  border-radius: 25px !important;
  padding: 0.25rem 1.25rem !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: theme('colors.grey.blue') !important;
  background: theme('colors.grey.light-concrete');

  &:hover {
    background: theme('colors.grey.blue') !important;
  }

  span {
    text-transform: none;
  }
}

.alert-button-role-cancel:hover {
  background-image: none;
  background-color: theme('colors.grey.light-concrete') !important;
}

.form-input ion-datetime-button::part(native) {
  padding: 10px 15px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  border: 1px solid theme('colors.grey.concrete');
}
.form-input ion-datetime-button {
  justify-content: left;
}

.default-grid .empty-state ion-icon {
  transform: none;
}

ion-item.no-hover {
  --background-hover: none;
}

.scroll-shadow {
  max-height: 200px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;

  background:
    /* Shadow Cover TOP */ linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    /* Shadow Cover BOTTOM */ linear-gradient(rgba(255, 255, 255, 0), white 75%) center bottom,
    /* Shadow TOP */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center top,
    /* Shadow BOTTOM */ radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom;

  background-repeat: no-repeat;
  background-size: 100% 50px, 100% 50px, 100% 15px, 100% 15px;
  background-attachment: local, local, scroll, scroll;
}

app-loader[inline='true'] {
  z-index: -1;
}

.preview-mode app-offering-selector-item {
  pointer-events: none;
}
.preview-mode {
  * {
    color: white !important;
  }
  .offering-category-section {
    background-color: transparent !important;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, rgba(0, 0, 0, 0) -19px),
      linear-gradient(0deg, rgba(255, 255, 255, 0.25) 1px, rgba(0, 0, 0, 0) 1px) !important;
    border: 2px solid white !important;
    background-position: 1px 0px;
    background-repeat: repeat;
    background-size: 28px 28px;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5) !important;
  }
  .recommended-offering {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3)) !important;
  }
  .featured-offering {
    border: 2px solid white !important;
    box-shadow: none !important;
  }
  .featured-offering:after,
  .featured-offering:before {
    display: none !important;
  }
  .non-featured-offering,
  #subcategory-featured,
  .sub-offering-category,
  .sub-offering-category:hover,
  .btn-link {
    background: transparent !important;
    box-shadow: none !important;
    border: 0.5px solid white !important;
  }
  app-offering-selector-item div.box-content,
  app-offering-card div {
    background: theme('colors.blue.bright') !important;
  }
  ion-img {
    filter: grayscale(100%) brightness(1000%);
  }
  app-offering-selector-item ion-img {
    filter: none;
  }
}
